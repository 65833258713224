<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Non-Compete and Non-Solicitation Agreement
                <br>
                <small>NCNSA Final 062321</small>
              </b-card-title>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form>

                  <b-form-input
                    v-model="form.form"
                    type="text"
                    class="hidden"
                  />

                  <b-form-input
                    v-model="form.id"
                    type="text"
                    class="hidden"
                  />

                  <b-row>

                    <!-- Input Recipient -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Recipient"
                        label-for="recipient"
                      >
                        <label
                          class="sr-only"
                          for="recipient"
                        >
                          * Recipient
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient"
                          vid="recipient"
                          rules="required"
                        >
                          <b-form-input
                            id="recipient"
                            ref="recipient"
                            v-model="form.recipient"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Recipient"
                            title="Recipient"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Effective Date -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Effective Date"
                        vid="effective-date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Effective Date"
                          label-for="effective-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="effective-date"
                          >
                            * Effective Date
                          </label>
                          <flat-pickr
                            id="effective-date"
                            ref="effectiveDate"
                            v-model="form.effectiveDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Effective Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <p>This is an Agreement between {{ form.recipient }} (“Recipient”) and 24/7 Circle of Care, LLC (“Company”). The Agreement is effective on {{ form.effectiveDate }} (“Effective Date”).</p>

                  <p>This Agreement, when signed and witnessed below, shall constitute an agreement regarding defined noncompete and non-solicitation agreement, confidential and proprietary information and trade secrets, hereinafter referred to as “Confidential Information,” relating to the business of 24/7 Circle of Care, LLC located at 260 Newport Center Dr., Ste 100 Newport Beach, CA 92660 and Recipient, hereinafter referred to as the “Parties”, as of the date executed, thus known as the “Effective Date.”</p>
                  <p>It shall be incumbent upon the Recipient to strictly maintain the confidentiality of the Proprietary Information. Proprietary information may be shared amongst the Parties for use in scoping, estimating and completing any and all work or projects for the Company and its clients.</p>

                  <p>In consideration of the employment opportunity provided by 24/7 Circle of Care, LLC, Recipient, intending to be legally bound, agree to the following:</p>
                  <ol>
                    <li><b>Term of Agreement.</b> This Agreement is effective on the “Effective Date” and shall remain in effect throughout the term of your employment with the Company and for a period of two (2) years thereafter.</li>
                    <li><b>Limitations of this Agreement.</b> This Agreement is not a contract of employment. Neither Recipient nor the Company are obligated to any specific term of employment. This Agreement is limited to the subject matter of covenants not to compete or solicit as described in this Agreement.</li>
                    <li><b>Covenant Not to Compete.</b> Recipient agree that at no time during the term of your employment with the Company will you engage in any business activity which is competitive with the Company. The Recipient shall not, in any manner, represent, provide services or engage in any aspects of business that would be deemed similar in nature to the business of 24/7 Circle of Care, LLC without the written consent of 24/7 Circle of Care, LLC.<br>The recipient warrants and guarantees that throughout the duration of this agreement and for a period <b>not to exceed two (2) years</b> following the culmination, completion or termination of this agreement, that Recipient shall not directly or indirectly engage in any business that would be considered similar in nature with 24/7 Circle of Care, LLC, its subsidiaries, and any current or former clients and/or customers within 100-mile radius of Newport Beach, California. Nor shall the Recipient solicit any client, customer, officer, staff or employee for the benefit of himself/herself or a third party that is or may be engaged in a similar business.</li>
                    <li>
                      <b>Confidential Information.</b> By definition herein, “Confidential Information” shall mean any and all technical and non-technical information provided by 24/7 Circle of Care, LLC including but not limited to, any data, files, reports, accounts, or any proprietary information in any way related to products, services, processes, database, plans, methods, research, development, programs, software, authorship, customer lists, vendor lists, suppliers, marketing or advertising plans, methods, reports, analysis, financial or statistical information, and any other material related or pertaining to any business of 24/7 Circle of Care, LLC, its subsidiaries, respective clients, consultants or vendors that may be disclosed to the Recipient herein contained within the terms of this Agreement.<br><small>Covenants not to compete are not favored by courts, so they generally are interpreted very narrowly. They must be “reasonable” in terms of duration and the geographical area to which they apply. Sometimes the duration can be as long as two or three years, while the size of the territory can be quite small, e.g., a 100-mile radius, or quite large, e.g., anywhere in the world.</small><br>The Recipient shall not in any manner or form, at any time disclose, reveal, unveil, divulge or release, either directly or indirectly, any aforementioned proprietary or confidential information for personal use or for the benefit of any third party and shall at all times endeavor to protect all Confidential Information belonging to the Company.
                      <br>

                      <!-- Input Recipient Initials -->
                      <b-row>

                        <b-col
                          cols="12"
                          lg="6"
                        >

                          <b-form-group
                            label="* Recipient Initials to acknowledge Items 1 to 4."
                            label-for="recipient-initials"
                            class="mt-1"
                          >
                            <label
                              class="sr-only"
                              for="recipient-initials"
                            >
                              * Recipient Initials to acknowledge Items 1 to 4.
                            </label>
                            <validation-provider
                              #default="{ errors }"
                              name="Recipient Initials to acknowledge Items 1 to 4"
                              vid="recipient-initials"
                              rules="required"
                            >
                              <b-form-input
                                id="recipient-initials"
                                v-model="recipientInitials"
                                :state="errors.length > 0 ? false : null"
                                type="text"
                                placeholder="Recipient Initials"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>

                        </b-col>
                      </b-row>
                    </li>
                    <li><b>Non-solicitation.</b> During the term of your employment, and for a period of two (2) years immediately thereafter, Recipient agree not to solicit any employee or independent contractor of the Company on behalf of any other business enterprise, nor shall Recipient induce any employee or independent contractor associated with the Company to terminate or breach an employment, contractual or other relationship with the Company.</li>
                    <li><b>Soliciting Customers After Termination of Agreement.</b> For a period of two (2) years following the termination of your employment and your relationship with the Company, Recipient shall not, directly or indirectly, disclose to any person, firm or corporation the names or addresses of any of the customers or clients of the Company or any other information pertaining to them. Neither shall Recipient call on, solicit, take away, or attempt to call on, solicit, or take away any customer of the Company on whom Recipient have called or with whom Recipient became acquainted during the term of your employment, as the direct or indirect result of your employment with the Company.</li>
                    <li><b>Injunctive Relief.</b> Recipient hereby acknowledge (1) that the Company will suffer irreparable harm if you breach your obligations under this Agreement; and (2) that monetary damages will be inadequate to compensate the Company for such a breach. Therefore, if Recipient breach any of such provisions, then the Company shall be entitled to injunctive relief amounting to not less than $10,000.00 (ten thousand US Dollars), in addition to any other remedies at law or equity, to enforce such provisions.</li>
                    <li><b>Severable Provisions.</b> The provisions of this Agreement are severable, and if any one or more provisions may be determined to be illegal or otherwise unenforceable, in whole or in part, the remaining provisions and any partially unenforceable provisions to the extent enforceable shall nevertheless be binding and enforceable.</li>
                    <li><b>Modifications.</b> This Agreement shall be considered a separate and an independent document of which it shall supersede any and all other Agreements, and there are no other assurances or conditions in any other instrument, either oral or written, between the parties hereto. This Agreement may be modified only by a subsequent written agreement signed by both parties.</li>
                    <li>
                      <b>Prior Understandings.</b> This Agreement contains the entire agreement between the parties with respect to the subject matter of this Agreement. The Agreement supersedes all prior understanding, agreements, or representations.
                      <br>

                      <!-- Input Recipient Initials 1 -->
                      <b-row>

                        <b-col
                          cols="12"
                          lg="6"
                        >

                          <b-form-group
                            label="* Recipient Initials to acknowledge Items 5 to 10."
                            label-for="recipient-initials1"
                            class="mt-1"
                          >
                            <label
                              class="sr-only"
                              for="recipient-initials1"
                            >
                              * Recipient Initials to acknowledge Items 5 to 10.
                            </label>
                            <validation-provider
                              #default="{ errors }"
                              name="Recipient Initials to acknowledge Items 5 to 10"
                              vid="recipient-initials1"
                              rules="required"
                            >
                              <b-form-input
                                id="recipient-initials1"
                                v-model="recipientInitials1"
                                :state="errors.length > 0 ? false : null"
                                type="text"
                                placeholder="Recipient Initials"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>

                        </b-col>
                      </b-row>
                    </li>
                    <li><b>Waiver.</b> Any waiver of a default under this Agreement must be made in writing and shall not be a waiver of any other default concerning the same or any other provision of this Agreement. No delay or omission in the exercise of any right or remedy shall impair such right or remedy or be constructed as a waiver. A consent to or approval of any act shall not be deemed to waive or render unnecessary consent to or approval of any other or subsequent act.</li>
                    <li><b>Governing Law.</b> This Agreement is to be construed pursuant to the laws of the State of California. You agree to submit to the jurisdiction and venue of any court of competent jurisdiction in Orange County, California without regard to conflict of law provisions, for any claim arising out of this Agreement.</li>
                  </ol>

                  <!-- Spacer -->
                  <hr>

                  <p><b>IN WITNESS WHEREOF,</b> the parties hereto have caused this Non-Compete and Non-Solicitation Agreement to be executed by a duly authorized representative of such party and of such party as of the effective date executed by the signature of both parties.</p>

                  <!-- Input Authorized Officer Signature -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Authorized Officer Signature"
                        label-for="authorized-officer-signature"
                      >
                        <label
                          class="sr-only"
                          for="authorized-officer-signature"
                        >
                          * Authorized Officer Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Authorized Officer Signature"
                          vid="authorized-officer-signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            id="authorized-officer-signature"
                            ref="authorizedOfficerSignature"
                            v-model="form.authorizedOfficerSignature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.authorizedOfficerSignature === '' }"
                            title="Authorized Officer Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.authorizedOfficerSignature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoAuthorizedOfficerSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearAuthorizedOfficerSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Input Authorized Officer Date Signed -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date Signed"
                        vid="authorized-officer-date-signed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date Signed"
                          label-for="authorized-officer-date-signed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="authorized-officer-date-signed"
                          >
                            * Date Signed
                          </label>
                          <flat-pickr
                            id="authorized-officer-date-signed"
                            ref="authorizedOfficerDateSigned"
                            v-model="form.authorizedOfficerDateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Authorized Officer Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input Authorized Officer -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Authorized Officer"
                        label-for="authorized-officer"
                      >
                        <label
                          class="sr-only"
                          for="authorized-officer"
                        >
                          * Authorized Officer
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Authorized Officer"
                          vid="authorized-officer"
                          rules="required"
                        >
                          <b-form-input
                            id="authorized-officer"
                            ref="authorizedOfficer"
                            v-model="form.authorizedOfficer"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Authorized Officer"
                            title="Authorized Officer"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <p>By your signature below, Recipient acknowledge that he/she have read and understand the foregoing Agreement, that Recipient agree to comply with all the terms of the Agreement, and that Recipient have received a copy of the Agreement.</p>

                  <!-- Input Signature -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Recipient Signature"
                        label-for="signature"
                      >
                        <label
                          class="sr-only"
                          for="signature"
                        >
                          * Recipient Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient Signature"
                          vid="recipient-signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            id="recipient-signature"
                            ref="signature"
                            v-model="form.signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.signature === '' }"
                            title="Recipient Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.signature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Input Date Signed -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date Signed"
                        vid="date-signed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date Signed"
                          label-for="date-signed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-signed"
                          >
                            * Date Signed
                          </label>
                          <flat-pickr
                            id="date-signed"
                            ref="dateSigned"
                            v-model="form.dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input Recipient -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Recipient"
                        label-for="recipient"
                      >
                        <label
                          class="sr-only"
                          for="recipient"
                        >
                          * Recipient
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient"
                          vid="recipient1"
                          rules="required"
                        >
                          <b-form-input
                            id="recipient1"
                            ref="recipient"
                            v-model="form.recipient"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Recipient"
                            title="Recipient"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form6', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form8', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueSignaturePad } from 'vue-signature-pad'
import Swal from 'sweetalert2'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,

    vSelect,
    flatPickr,
    VueSignaturePad,
    // eslint-disable-next-line
    Swal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const recipientInitials = ref('')
    const recipientInitials1 = ref('')

    const authorizedOfficerSignature = ref(null)
    const signature = ref(null)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          // console.log(jsonObjectLiterals.data)

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          // eslint-disable-next-line
          form.value.recipient = jsonObjectLiterals.data.firstName + (jsonObjectLiterals.data.middleName ? ' ' + jsonObjectLiterals.data.middleName + ' ' : ' ') + jsonObjectLiterals.data.lastName

          // recipientInitials.value = jsonObjectLiterals.data.firstName.substring(0, 1) + jsonObjectLiterals.data.lastName.substring(0, 1)
          // recipientInitials1.value = jsonObjectLiterals.data.firstName.substring(0, 1) + jsonObjectLiterals.data.lastName.substring(0, 1)

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm7', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                if (jsonObjectLiterals.data.authorizedOfficerSignature) {
                  authorizedOfficerSignature.value.fromDataURL(jsonObjectLiterals.data.authorizedOfficerSignature)
                }

                if (jsonObjectLiterals.data.signature) {
                  signature.value.fromDataURL(jsonObjectLiterals.data.signature)
                }

                if (form.value.id) {
                  form.value.form = 'folder3_form7'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      // wrap: true, // set wrap to true only when using 'input-group'
      // altFormat: 'm/d/Y',
      // altInput: true,
      dateFormat: 'Y-m-d',
    }

    const vueAuthorizedOfficerSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      recipientInitials,
      recipientInitials1,

      authorizedOfficerSignature,
      signature,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      flatPickrConfig,
      vueAuthorizedOfficerSignaturePadOptions,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.authorizedOfficerSignature) {
            self.$refs.authorizedOfficerSignature.clearSignature()
            self.$refs.authorizedOfficerSignature.fromDataURL(jsonObjectLiterals.data.authorizedOfficerSignature)
          }

          if (jsonObjectLiterals.data.signature) {
            self.$refs.signature.clearSignature()
            self.$refs.signature.fromDataURL(jsonObjectLiterals.data.signature)
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      /* let object = { isEmpty: null, data: null }

      object = this.$refs.authorizedOfficerSignature.saveSignature()
      this.form.authorizedOfficerSignature = !object.isEmpty ? object.data : ''

      object = this.$refs.signature.saveSignature()
      this.form.signature = !object.isEmpty ? object.data : '' */

      this.$refs.simpleRules.validate().then(success => {
        /* this.isNotSaved = true

        store
          .dispatch(this.action === 'add' ? 'app-hr/addForm7' : 'app-hr/updateForm7', this.form)
          .then(response => {
            // eslint-disable-next-line
            const jsonObjectLiterals = response.data.response.data

            this.form.id = jsonObjectLiterals.id

            // console.log(jsonObjectLiterals)

            Swal.fire({
              icon: 'success',
              title: 'Saved!',
              text: 'Saved successfully.',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(error => {
            console.error(error)

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .finally(() => {
            self.isNotSaved = false
          }) */
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm7' : 'app-hr/updateForm7', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  // console.log(jsonObjectLiterals)

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })

          // eslint-disable-next-line
          // alert('form submitted!')
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    clearAuthorizedOfficerSignature() {
      this.$refs.authorizedOfficerSignature.clearSignature()

      const { isEmpty, data } = this.$refs.authorizedOfficerSignature.saveSignature()
      this.form.authorizedOfficerSignature = !isEmpty ? data : ''

      if (this.form.authorizedOfficerSignature === '') {
        this.$refs.simpleRules.refs['authorized-officer-signature'].reset()
      }
    },
    undoAuthorizedOfficerSignature() {
      this.$refs.authorizedOfficerSignature.undoSignature()

      const { isEmpty, data } = this.$refs.authorizedOfficerSignature.saveSignature()
      this.form.authorizedOfficerSignature = !isEmpty ? data : ''

      if (this.form.authorizedOfficerSignature === '') {
        this.$refs.simpleRules.refs['authorized-officer-signature'].reset()
      }
    },
    clearSignature() {
      this.$refs.signature.clearSignature()

      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''

      if (this.form.signature === '') {
        this.$refs.simpleRules.refs['recipient-signature'].reset()
      }
    },
    undoSignature() {
      this.$refs.signature.undoSignature()

      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''

      if (this.form.signature === '') {
        this.$refs.simpleRules.refs['recipient-signature'].reset()
      }
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      let object = { isEmpty: null, data: null }

      object = this.$refs.authorizedOfficerSignature.saveSignature()
      this.form.authorizedOfficerSignature = !object.isEmpty ? object.data : ''

      object = this.$refs.signature.saveSignature()
      this.form.signature = !object.isEmpty ? object.data : ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
